<template>
  <div class="public">
    <div class="container HomePage">
      <!-- 四色码 -->
      <div class="idiot-one">
        <div class="box" v-for="(item, i) in forCodeData" :key="i">
          <div class="box-header">
            <span>{{
                item.safeCodeColor == 1
                    ? "灰码"
                    : item.safeCodeColor == 2
                    ? "红码"
                    : item.safeCodeColor == 3
                        ? "黄码"
                        : item.safeCodeColor == 4
                            ? "绿码"
                            : item.safeCodeColor == 5
                                ? "黑码"
                                : ""
              }}</span>
            <span>{{ item.busNum }}</span
            >家
          </div>
          <div class="box-one">
            <span>较昨日：</span>
            <span>{{ item.yesterdayNum }}</span>
            <span
            ><i
                :class="
                  item.yesterdayNum > 0
                    ? 'el-icon-top t'
                    : item.yesterdayNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
            ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上周：</span>
            <span>{{ item.weekNum }}</span>
            <span
            ><i
                :class="
                  item.weekNum > 0
                    ? 'el-icon-top t'
                    : item.weekNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
            ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上月：</span>
            <span>{{ item.monthNum }}</span>
            <span
            ><i
                :class="
                  item.monthNum > 0
                    ? 'el-icon-top t'
                    : item.monthNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
            ></i
            ></span>
          </div>
          <div class="box-one">
            <span>较上年：</span>
            <span>{{ item.yearNum }}</span>
            <span
            ><i
                :class="
                  item.yearNum > 0
                    ? 'el-icon-top t'
                    : item.yearNum < 0
                    ? 'el-icon-bottom b'
                    : ''
                "
            ></i
            ></span>
          </div>
        </div>
      </div>
      <!-- 商户区域分布情况 中国地图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>企业商户区域分布图</span>
          </div>
        </div>

        <div class="map-charts">

          <Baidumap
              v-loading="showbmap"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(9,32,57, 0.1)"
              v-if="regionType >= 3 && areaName"
              :regionName="regionName"
              :flag="flag"
              :cityName="cityName"
              :areaName="areaName"
              :data="baidumapOptions"
              ref="baidumap"
          />
          <Pmap
              v-else
              v-loading="showmap"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(9,32,57, 0.1)"
              :regionName="regionName"
              :cityName="cityName"
              :flag="flag"
              :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 预付码新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>企业商户新增变化趋势</span>
          </div>
          <div class="echarts-but">
            <el-radio-group v-model="radio1">
              <el-radio-button
                  v-for="item in radioData1"
                  :label="item.value"
                  :key="item.value"
              >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <line-chart
              v-loading="lineChart1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :chartData="lineChartData1"
              :legendData="['灰码', '红码', '黄码', '绿码', '黑码']"
              :units="['家', '家', '家', '家', '家']"
              :type="radio1"
          ></line-chart>
        </div>
      </div>
      <!-- 预付码累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>企业商户累计变化趋势</span>
          </div>
          <div class="echarts-but">
            <el-radio-group v-model="radio2">
              <el-radio-button
                  v-for="item in radioData1"
                  :label="item.value"
                  :key="item.value"
              >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>

        <div class="charts">
          <line-chart
              v-loading="lineChart2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :chartData="lineChartData2"
              :legendData="['灰码', '红码', '黄码', '绿码', '黑码']"
              :units="['家', '家', '家', '家', '家']"
              :type="radio2"
          ></line-chart>
        </div>
      </div>
      <!-- 预付码按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>企业商户区域分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType1" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                  v-for="item in options"
                  :key="item.haripinType"
                  :label="item.name"
                  :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
              :data="tableDataAll"
              style="width: 100%"
              :row-key="rowKey"
              lazy
              v-loading="loading1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :load="loadNextNode1"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort"
          >
            <el-table-column
                prop="regionName"
                label="地区"
                sortable
                width="290"
            >
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="greyNum" label="灰码" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.greyNum }}</span
                ><i
                  :class="
                    scope.row.greySign > 0
                      ? 'num-badge-blue'
                      : scope.row.greySign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.greySign > 0
                      ? "+" + scope.row.greySign
                      : scope.row.greySign < 0
                      ? "" + scope.row.greySign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="redNum" label="红码" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.redNum }}</span
                ><i
                  :class="
                    scope.row.redSign > 0
                      ? 'num-badge-blue'
                      : scope.row.redSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.redSign > 0
                      ? "+" + scope.row.redSign
                      : scope.row.redSign < 0
                      ? "" + scope.row.redSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="yellowNum" label="黄码" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.yellowNum }}</span
                ><i
                  :class="
                    scope.row.yellowSign > 0
                      ? 'num-badge-blue'
                      : scope.row.yellowSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.yellowSign > 0
                      ? "+" + scope.row.yellowSign
                      : scope.row.yellowSign < 0
                      ? "" + scope.row.yellowSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="greenNum" label="绿码" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.greenNum }}</span
                ><i
                  :class="
                    scope.row.greenSign > 0
                      ? 'num-badge-blue'
                      : scope.row.greenSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.greenSign > 0
                      ? "+" + scope.row.greenSign
                      : scope.row.greenSign < 0
                      ? "" + scope.row.greenSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="blackNum" label="黑码" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.blackNum }}</span
                ><i
                  :class="
                    scope.row.blackSign > 0
                      ? 'num-badge-blue'
                      : scope.row.blackSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.blackSign > 0
                      ? "+" + scope.row.blackSign
                      : scope.row.blackSign < 0
                      ? "" + scope.row.blackSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>

          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
            >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                  class="el-icon-d-arrow-left el-icon--right"
                  :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
              ></el-button>
          </div>
        </div>
      </div>
      <!-- 预付码按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>企业商户领域/行业分布数量统计</span>
          </div>
          <div class="h-selct">
            <el-select v-model="hairpinType2" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                  v-for="item in options"
                  :key="item.haripinType"
                  :label="item.name"
                  :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
              :data="tableDataAll2"
              style="width: 100%"
              v-loading="loading2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              row-key="industryId"
              lazy
              :load="loadNextNode2"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="industryName" label="领域/行业" sortable>
            </el-table-column>
            <el-table-column prop="num" label="总计" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num }}</span
                ><i
                  :class="
                    scope.row.numSign > 0
                      ? 'num-badge-blue'
                      : scope.row.numSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.numSign > 0
                      ? "+" + scope.row.numSign
                      : scope.row.numSign < 0
                      ? "" + scope.row.numSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="greyNum" label="灰码" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.greyNum }}</span
                ><i
                  :class="
                    scope.row.greySign > 0
                      ? 'num-badge-blue'
                      : scope.row.greySign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.greySign > 0
                      ? "+" + scope.row.greySign
                      : scope.row.greySign < 0
                      ? "" + scope.row.greySign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="redNum" label="红码" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.redNum }}</span
                ><i
                  :class="
                    scope.row.redSign > 0
                      ? 'num-badge-blue'
                      : scope.row.redSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.redSign > 0
                      ? "+" + scope.row.redSign
                      : scope.row.redSign < 0
                      ? "" + scope.row.redSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="yellowNum" label="黄码" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.yellowNum }}</span
                ><i
                  :class="
                    scope.row.yellowSign > 0
                      ? 'num-badge-blue'
                      : scope.row.yellowSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.yellowSign > 0
                      ? "+" + scope.row.yellowSign
                      : scope.row.yellowSign < 0
                      ? "" + scope.row.yellowSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="greenNum" label="绿码" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.greenNum }}</span
                ><i
                  :class="
                    scope.row.greenSign > 0
                      ? 'num-badge-blue'
                      : scope.row.greenSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.greenSign > 0
                      ? "+" + scope.row.greenSign
                      : scope.row.greenSign < 0
                      ? "" + scope.row.greenSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="blackNum" label="黑码" sortable>
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.blackNum }}</span
                ><i
                  :class="
                    scope.row.blackSign > 0
                      ? 'num-badge-blue'
                      : scope.row.blackSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
              >{{
                  scope.row.blackSign > 0
                      ? "+" + scope.row.blackSign
                      : scope.row.blackSign < 0
                      ? "" + scope.row.blackSign
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>

          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
            >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                  class="el-icon-d-arrow-left el-icon--right"
                  :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
              ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line"; // 四码折线图
import Pmap from "@/components/charts/map.vue"; //地图
import Baidumap from "@/components/baidumap/index.vue";
import {
  regionalDistributionInfo,
  fourCodeStatistics,
  safeCodeAdd,
  safeCodeCumulative,
  safeCodeCityStatistics,
  safeCodeIndustryStatistics,
} from "@/api/homepage";
import { cardType } from "@/api/common";

export default {
  name: "HomePage",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
    change: [Number],
  },
  data() {
    return {
      showmap: true,
      showbmap: true,
      lineChart1: false,
      lineChart2: false,
      hairpinType1: "",
      hairpinType2: "",
      options: [],
      radio1: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData1: {
        xAxisData: [],
        seriesData: [],
      },
      radio2: "1",
      lineChartData2: {
        xAxisData: [],
        seriesData: [],
      },
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      mapOptions: [],
      baidumapOptions: [],
      forCodeData: [],
      cerditStatistic: [],
      citylistname: "中国",
    };
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        change: this.change,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        console.log("HomePage======", val);
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.safeCodeAdd();
    },
    radio2: function (val) {
      this.safeCodeCumulative();
    },

    hairpinType1: function (val) {
      this.safeCodeCityStatistics();
    },
    hairpinType2: function (val) {
      this.safeCodeIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  methods: {
    intData() {
      this.cardType();
      this.safeCodeAdd();
      this.safeCodeCumulative();
      this.safeCodeCityStatistics();
      this.safeCodeIndustryStatistics();
      this.fourCodeStatistics();
      this.getMapOptionData();
    },
    cardType() {
      cardType().then((res) => {
        this.options = res.data;
      });
    },
    getMapOptionData() {
      this.showbmap=true;
      this.showmap=true;
      regionalDistributionInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
          .then((res) => {
            // console.log("商户区域分布情况:", res);
            //this.mapOptions = res.data;

            let data = [];
            res.data.forEach((item) => {
              data.push({
                name: item.regionName,
                value: item.num,
                data: item.data,
              });
            });
            this.showmap = false;
            this.mapOptions = data;
            if (this.regionType >= 3 && this.areaName) {
              this.baidumapOptions = data;
              this.showbmap = false;
            }

            // console.log(this.mapOptions, "ditu");
          })
          .catch((err) => {
            console.log(err);
          });
    },

    fourCodeStatistics() {
      fourCodeStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
          .then((res) => {
            //this.forCodeData = res.data;
            // let arr = []
            // arr[0] = res.data[0]
            // arr[1] = res.data[1]
            // arr[2] = res.data[2]
            // arr[3] = res.data[3]
            // arr[4] = res.data[4]
            // console.log(res.data, "ddddddd");
            this.forCodeData = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    safeCodeAdd() {
      this.lineChart1 = true;
      safeCodeAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      })
          .then((res) => {
            //console.log('预付码新增变化趋势',res)
            this.lineChart1 = false;
            if (res.data != null) {
              this.lineChartData1 = {
                xAxisData: res.data.x_axis,
                seriesData: [
                  {
                    name: "灰码",
                    data: res.data.grey,
                  },
                  {
                    name: "红码",
                    data: res.data.red,
                  },
                  {
                    name: "黄码",
                    data: res.data.yellow,
                  },
                  {
                    name: "绿码",
                    data: res.data.green,
                  },
                  {
                    name: "黑码",
                    data: res.data.black,
                  },
                ],
              };
            } else {
              this.lineChartData1 = {
                xAxisData: [],
                seriesData: [],
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    safeCodeCumulative() {
      this.lineChart2 = true;
      safeCodeCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
      })
          .then((res) => {
            this.lineChart2 = false;
            //console.log('预付码累计变化趋势',res)
            if (res.data != "") {
              this.lineChartData2 = {
                xAxisData: res.data.x_axis,
                seriesData: [
                  {
                    name: "灰码",
                    data: res.data.grey,
                  },
                  {
                    name: "红码",
                    data: res.data.red,
                  },
                  {
                    name: "黄码",
                    data: res.data.yellow,
                  },
                  {
                    name: "绿码",
                    data: res.data.green,
                  },
                  {
                    name: "黑码",
                    data: res.data.black,
                  },
                ],
              };
            } else {
              this.lineChartData2 = {
                xAxisData: [],
                seriesData: [],
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    safeCodeCityStatistics() {
      this.loading1 = true;
      safeCodeCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        // console.log("预付码按城市/地区统计", res);
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data || [];
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      safeCodeCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        resolve(res.data || []);
      });
    },
    safeCodeIndustryStatistics() {
      this.loading2 = true;
      safeCodeIndustryStatistics({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计',res)
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data || [];
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      safeCodeIndustryStatistics({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  mounted() {
    // console.log(sessionStorage.getItem("initParams"))
    if (sessionStorage.getItem("initParams") === "init") {
      console.log(123123);
      this.intData();
    }

    // this.intData();
  },
  beforeDestroy() {
    window.sessionStorage.setItem("initParams", "init");
  },
  components: {
    LineChart,
    Pmap,
    Baidumap,
  },
};
</script>

<style lang="scss" scoped >
.HomePage {
  .tab-content {
    margin: 0 30px 20px 30px;
  }
  // 四色码
  .idiot-one {
    display: flex;
    flex-wrap: wrap;
    height: 240px;
    margin: 0 30px 30px 30px;
    border: 1px solid #17688d;
    background: linear-gradient(
            to bottom,
            rgba(43, 202, 255, 0.1),
            #0000,
            rgba(43, 202, 255, 0.1)
    );
    .box {
      width: 19.7%;
      text-align: center;
      border: 0;
      border-right: 1px solid #ddd;
      border-image: linear-gradient(
              #0000,
              #0000,
              #17688d,
              #17688d,
              #0000,
              #0000
      )
      30 30;

      .box-header {
        color: oldlace;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        span:nth-child(1) {
          font-size: 18px;
        }
        span:nth-child(2) {
          font-size: 30px;
          margin: 0 10px;
        }
      }
      .box-one {
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 32px;
        span:nth-child(1) {
          color: #9fa1a7;
        }
        span:nth-child(2) {
          color: #eee;
          width: 60px;
          text-align: left;
        }
        span:nth-child(3) {
          color: #eee;
          width: 10px;
          font-size: 16px;
          .t {
            color: #ffba00;
            font-weight: bold;
          }
          .b {
            color: #59d2f3;
            font-weight: bold;
          }
        }
      }
      &:nth-child(1) {
        .box-header {
          span:nth-child(2) {
            color: #fff;
          }
        }
      }
      &:nth-child(2) {
        .box-header {
          span:nth-child(2) {
            color: #ff4646;
          }
        }
      }
      &:nth-child(3) {
        .box-header {
          span:nth-child(2) {
            color: #fecb01;
          }
        }
      }
      &:nth-child(4) {
        .box-header {
          span:nth-child(2) {
            color: #49e71f;
          }
        }
      }
      &:nth-child(5) {
        border-right: 0;
        .box-header {
          span:nth-child(2) {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
